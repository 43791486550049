.card {
  background: #ffffff;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 8px;
  border: 2px solid #ebecf0;
  word-break: break-all;
  /* word-wrap: break-word; */
}

.card-draggable {
  cursor: grab;
}

.card-not-draggable {
  cursor: auto;
}

.card-draggable:hover {
  background-color: #f9f3f3;
}

.card-button-container {
  width: 20px;
  margin-top: -10px;
  margin-bottom: 0px;
  display: inline-block;
  position: relative;
  top: 4px;
}

.card-view-button-container {
  margin-right: 6px;
}

.card-link {
  cursor: pointer;
}

.card-title {
  font-weight: normal;
  font-size: 1.1em;
  word-break: break-word;
}

.card-assessment-name {
  color: #172b4d;
  font-weight: bold;
}

.card-definition-name {
  color: #000;
  font-weight: bold;
}

.card-floating-score {
  float: right;
  width: 40px;
  margin-left: 6px;
  margin-right: 0px;
}

.card-description {
  height: 5.5em;
  display: inline-block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.card-description::-webkit-scrollbar {
  width: 0 !important;
}

.card-description h1, .card-description h2, .card-description h3, .card-description h4, 
.card-description h5, .card-description h5 {
  font-size: 1em;
  font-weight: bold;
  margin-top: 0.3em;
  margin-right: 0.5em;
  display: inline-block;
  /* word-wrap: break-word; */
}

.card-description p {
  display: inline;
}

.card-description ul {
  /* word-wrap: break-word; */
}
