
div.resizing-text-area-container-view-mode {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 7px;
}

div.resizing-text-area-container-view-mode:hover {
  background-color: #EBECF0; /* adg.widgetBackgroundColorHover */
  border-color: #EBECF0; /* adg.widgetBackgroundColorHover */
}
