
.expanding-text-container {
  overflow-y: hidden;
}

.collapse-button-over-text {
  margin-top: 10px;
}

.expand-button-over-text {
  padding: 6px 0px;
  width: fit-content;
}
