.lists {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  list-style-type: none;
}

.list-wrapper {
  margin-bottom: 9px;
  padding: 0px;
  flex-basis: 24%;
  min-width: 170px;
  border-radius: 10px;
  margin-top: 0px;
}
