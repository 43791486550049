.list-drop-zone {
  margin-top: 20px;
  padding-bottom: 200px;
  border: 4px dashed transparent;
  border-radius: 8px;
}

.list-container {
  /*margin: -4px;*/
  margin: 0px;
  border-radius: 8px;
  background-color: #ebecf0;
}

.list {
  list-style-type: none;
  padding-left: 0;
  background: #ebecf0;
  margin: 0 5px;
  padding: 8px;
  border-radius: 2px;
}

.empty-list-item {
  text-align: center;
  color: #172b4d;
  font-style: italic;
}

.name-header {
  color: #172b4d;
  text-align: center;
  padding: 6px 6px;
  margin: 0;
}
